.top-content-mesik {
	background: #67686a url(../images/mesik/page-bg.jpg) center -30px no-repeat;
	padding: 0 0 40px;
	min-height: 350px;
	max-height: 350px;
	min-width: 1210px;
@media (max-width: 992px) {
	min-height: 580px;
	min-width: 300px;
}
@media (max-width: 1199px) {
	min-height: 405px;
}

h1 {
	color: #fff;
	margin-bottom: 0;
	text-align: center;
	position: absolute;
	top: 110px;
@media (max-width: 1199px) {
	top: 28px;
}
        @media (--lg-viewport) {
            text-align: left;
        }
	}
}

.sprinter-menu {
	position: absolute;
	top: 50px;
	margin-left: auto;
	margin-right: auto;
	left: 0;
	right: 0;
	text-align: center;
@media (max-width: 1000px) {
	top: auto;
}
}

.lerox-menu {
	position: absolute;
	top: 50px;
	margin-left: auto;
	margin-right: auto;
	left: 0;
	right: 0;
	text-align: center;
@media (max-width: 1000px) {
	top: auto;
}
h1 {
	color: #444;
}
}

.top-content-sprinter {
	background: #EE7D00 url(../images/sprinter/page-bg.png) center 60px no-repeat;
	padding: 0 0 40px;
	min-height: 380px;
	max-height: 380px;
	min-width: 1210px;
	border-bottom: solid 3px #ffffff;

@media (max-width: 1199px) {
	min-height: 405px;
	min-width: 320px;
}

@media (max-width: 1000px) {
	min-height: 700px;
	min-width: 300px;
}

@media (max-width: 768px) {
	min-height: 500px;
}

h1 {
	color: #fff;
	margin-bottom: 0;
	text-align: center;
	position: absolute;
	top: 60px;
	margin-left: 35%;
@media (max-width: 1199px) {
	top: 28px;
}
@media (max-width: 985px) {
	top: 30px;
	margin-left: 10%;
}

}
}

.top-content-lerox {
	background: linear-gradient(rgba(180,180,180,1),rgba(10,10,10,0)), #f6f6f6 url(../images/lerox/page-bg.png) right no-repeat;
	background-size: 30%;
	padding: 0 0 40px;
	min-height: 380px;
	max-height: 380px;
	min-width: 1210px;
	border-bottom: solid 3px #ffffff;

@media (max-width: 1199px) {
	min-height: 405px;
	min-width: 320px;
}

@media (max-width: 1000px) {
	min-height: 700px;
	min-width: 300px;
}

@media (max-width: 768px) {
	min-height: 500px;
}

h1 {
	color: #444;
	margin-bottom: 0;
	text-align: center;
	position: absolute;
	top: 60px;
	margin-left: 35%;
@media (max-width: 1199px) {
	top: 28px;
}
@media (max-width: 985px) {
	top: 30px;
	margin-left: 10%;
}

}
}
