
.icon-map-prague {
	width: 188px;
	height:150px;
	fill: var(--red-dark);
}

.icon-map-cr {
	width: 206px;
	height:150px;
	fill: var(--red-dark);
}

.icon-map-eu {
	width: 176px;
	height:195px;
	fill: var(--red-dark);
}

.icon-express {
	width: 132px;
	height:110px;
	fill: var(--red-dark);
}

.icon-map-cr2 {
	width: 136px;
	height:110px;
	fill: var(--red-dark);
}

.icon-man {
	width: 100px;
	height:110px;
	fill: var(--red-dark);
}

.icon-monitor {
	width: 102px;
	height:110px;
	fill: var(--red-dark);
}

.icon-credit-card {
	width: 102px;
	height:110px;
	fill: var(--red-dark);
}

.icon-twenty-four-seven {
	width: 100px;
	height:110px;
	fill: var(--red-dark);
}

.icon-copy {
	width:22px;
	height:22px;
	fill: var(--gray);
}

.icon-print {
	width:28px;
	height:28px;
	fill: var(--gray);
}

.icon-paperclip {
	width:24px;
	height:24px;
	fill: var(--gray);
}

.icon-filter {
	width:26px;
	height:26px;
	fill: var(--gray);
}

.icon-refresh {
	width:28px;
	height:28px;
	fill: var(--gray);
}

.icon-file-excel {
	width:26px;
	height:26px;
	fill: var(--gray);
}

.icon-trash {
	width:24px;
	height:24px;
	fill: var(--gray);
}

.icon-pencil {
	width:24px;
	height:24px;
	fill: var(--gray);
}

.icon-trolley {
    width: 26px;
    height: 16px;
    fill: #fff;
}

.icon-eye {
    width: 24px;
    height: 24px;
    fill: var(--gray);
}

.icon-bin {
	width:28px;
	height:28px;
	fill: var(--gray);
}

.icon-kebab {
	width:28px;
	height:28px;
	fill: var(--gray);
}
.icon-circle-right {
	width:32px;
	height:32px;
	fill: var(--gray);
}
.icon-circle-right {
	width:32px;
	height:32px;
	fill: var(--gray);
}