._visuallyHidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

._hide, .hidden {
	display: none !important;
}

._underline {
	text-decoration: underline;
}

._uppercase {
    text-transform: uppercase;
}

._white {
	color: #fff;
}

._red {
	color: var(--red);
}

._gray {
	color: var(--gray-text);
}

._green {
	color: var(--green);
}

._mt0 {
	margin-top: 0 !important;
}

._mb0 {
    margin-bottom: 0;
}

._mt15 {
	margin-top: 15px !important;
}

._pt0 {
	padding-top: 0 !important;
}

._pl20 {
    padding-left: 20px !important;
}