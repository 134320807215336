.messages{
	padding: 20px;
    border-radius: 4px;
    margin: 20px 0;
    color: #fff;

    a {
        color: #fff;
    }

    p {
        margin: 5px 0;
    }

    &.success {
        background: var(--green)
    }

    &.info, &.warning {
        background: var(--orange)
    }

    &.error {
        background: var(--red)
    }
}
