body {
	font-family: var(--Neosans);
	font-size: 15px;
	line-height: 1.55em;
	font-weight: 400;
	color: var(--webColor);
	background: #fff;
}

ul, ol {
	margin: 0 0 25px 0;
}

ul {
	list-style-type: none;
	padding: 0;
}

table {
	margin-bottom: 35px;
}

p {
	margin: 0 0 15px 0;
}

img, iframe {
	max-width: 100%;
}

img {
    margin-bottom: 15px;
}

iframe {
	margin: 25px 0;
}

a {
	color: var(--webColor);
	text-decoration: underline;
	transition: all .3s;

	&:hover {
		text-decoration: none;
		color: var(--red);
	}
}

strong, b {
	font-weight: 700;
}