.services {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	margin-bottom: 0;

	&__item {
		font-size: 18px;
		line-height: 1.45em;
		width: 50%;
		padding: 15px;
		text-align: center;
		margin-bottom: 0;

		&::before {
			content: none;
		}

		p{
			margin-bottom: 0;
		}

		@media (--sm-viewport) {
			width: 33%;
			padding-left: 20px;
			padding-right: 20px;
		} @media (--lg-viewport) {
		font-size: 20px;
		width: 20%;
		padding-top: 0;
		padding-bottom: 0;
	}
	}

	.icon {
		fill: var(--red-dark);
		max-width: 100%;
		margin-bottom: 10px;

		@media (--max-575-viewport) {
			height: 70px;
			margin-bottom: 20px;
		}
	}
}