.filter {
    margin: 10px 0 30px;

    .row + .row {
        margin-top: 15px;
    }

    &.-expanded {
        .row {
            margin-left: -10px;
            margin-right: -10px;

            [class*='col-'] {
                padding-left: 10px;
                padding-right: 10px;
            }
        }
    }

    &__inputs {
        padding: 20px 20px 45px;
        background: var(--gray-light);

        &__item {
            label {
                display: block;
                margin: 0;
                padding: 0 0 10px 0;
            }

            input[type="text"], input[type="number"], input[type="password"], select, .custom-select {
                width: 100%;
                min-width: 10px;
                max-width: none;
            }

            input[type="text"], input[type="number"], .custom-select {
                margin-bottom: 10px;

                @media (--lg-viewport) {
                    margin-bottom: 0;
                }
            }
        }
    }

    &__top-row {
        margin-bottom: 15px;

        [class*='col-'] {
           margin-bottom: 10px;
        }

        .fulltext-search {
            margin-right: 15px;
        }

        .datepicker {
            @media (--max-767-viewport) {
                width: 100%;
            }
        }

        button {
            margin: 0 4px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__buttons {
        margin-top: -30px;

        @media (--lg-viewport) {
            display: flex;
            justify-content: center;
            align-items: center;
        }

@media (max-width: 991.98px) {
	margin-top: 30px;
}

         .-export {
            margin: 0 10px 10px 10px;
             position: relative;
             top: 8px;

             @media (--lg-viewport) {
                position:static;
             }
        }

        .button {
            display:block;
            width: 100%;
            margin: 10px auto;

            @media (--md-viewport) {
                margin: 0 10px 10px 10px;
                display: inline-block;
                width: auto;
            }

            @media (--lg-viewport) {
                min-width: 250px;

			&.-compact {
				 min-width: 25px;
			 }
            }
        }
    }

    button {
        &.-export {
            padding: 9px;
            border: 2px solid var(--gray-border);
            border-radius: 3px;
            display: inline-flex;
            justify-content: center;
            align-items: center;

            .icon {
                width: 30px;
            }
        }
    }
}

.sub-filter {
    margin: 20px 0;
}
