table {
	width: 100%;
}

th, td {
	border: 1px solid #fff;
	padding: 10px 20px;

	.icon {
		margin:5px 10px;
	}
}

th {
	color: #fff;
	background: var(--gray);
	padding-top: 10px;
	padding-bottom: 10px;

	em {
		font-weight: 400;
	}
}

tr.printable > th, tr.printable > td {
	background: var(--gray-lighter) !important;
	padding: 5px 10px;
}

tr.printable > th, tr.printable > td {
	color: black;
	background: var(--gray-lighter) !important;
	padding: 3px 10px;
}

table.printable {
	margin-bottom: 0;
	line-height: 1.1em;
}

td {
	background: var(--gray-lighter)
}

tr:nth-child(2n) {
	td {
		background: var(--gray-light)
	}
}

.scroll-table {
	margin: 25px 0;
	padding-bottom: 20px;
	overflow-x: auto;
	scrollbar-color: #08d496 #94ffe6;

	table {
		margin: 0;

		@media (--max-991-viewport) {
			width: 800px;
		}
	}

	@media (--md-viewport) {
		padding-bottom: 0;
	}
}