.sidebar-inner{
    transform: translate(0, 0); /* For browsers don't support translate3d. */
    transform: translate3d(0, 0, 0);
    will-change: position, transform;

    @media only screen and (max-width: 1599px) {
           position: static!important
        }

	@media only screen and (min-width: 1600px) {
		width: 105px!important
	}
 }

.right-sidebar {
    will-change: min-height;

	@media only screen and (min-width: 1600px) {
		position: fixed!important;
		transform: translateY(-50%);
		top: 50%;
		right: 120px;
		padding: 0;
	}

	@media only screen and (min-width: 1600px) {
		right: 60px;
	}

	@media only screen and (min-width: 1720px) {
		right: 120px;
	}

	@media only screen and (min-width: 1875px) {
		right: 190px;
	}
	@media only screen and (min-width: 2110px) {
		right: 300px;
	}
	@media only screen and (min-width: 2230px) {
		right: 360px;
	}
	@media only screen and (min-width: 2360px) {
		right: 425px;
	}
	@media only screen and (min-width: 2550px) {
		right: 520px;
	}
	@media only screen and (min-width: 2700px) {
		right: 590px;
	}
	@media only screen and (min-width: 2810px) {
		right: 650px;
	}
	@media only screen and (min-width: 3300px) {
		right: 890px;
	}
	@media only screen and (min-width: 3820px) {
		right: 1140px;
	}
	@media only screen and (min-width: 4090px) {
		right: 1280px;
	}
	@media only screen and (min-width: 4520px) {
		right: 1490px;
	}
	@media only screen and (min-width: 4850px) {
		right: 1660px;
	}
	@media only screen and (min-width: 5020px) {
		right: 1735px;
	}
	@media only screen and (min-width: 5280px) {
		right: 1880px;
	}
	@media only screen and (min-width: 5420px) {
		right: 1945px;
	}
	@media only screen and (min-width: 5760px) {
		right: 2100px;
	}
	@media only screen and (min-width: 6060px) {
		right: 2250px;
	}
	@media only screen and (min-width: 6200px) {
		right: 2330px;
	}
	@media only screen and (min-width: 6420px) {
		right: 2420px;
	}

    .right-menu {
		display: flex;

        @media (--max-767-viewport) {
            &.-opened {
                display: block;
            }
        }

        @media only screen and (min-width: 1600px) {
            display: block;
        }

        li {
            padding-left: 0;
            margin: 0;
            background: var(--green);
            transition: background-color .3s;
            border-bottom: 1px solid rgba(0, 0, 0, .3);
            text-transform: uppercase;

            &:hover {
                background: var(--gray-dark);

                a.right-menu__item {
                    color: var(--green);

                    .icon {
                        fill: var(--green)
                    }
                }
            }

            &::before {
                content: none;
            }

            &:last-child {
                border-bottom: none;
            }

            &.-active {
                background: var(--gray-dark);
                color: var(--green);

                .icon {
                    fill: var(--green)
                }
            }

            br {
                display: none;
            }

            @media (max-width: 1599px) {
                width: 16.6%;
                border-left: 1px solid rgba(0, 0, 0, .3);
                border-bottom: none;

                &:first-child {
                    border-left: none;
                }
            }

            @media only screen and (min-width: 1620px) {
                width: auto;
                border-left: none;
                border-bottom: 1px solid rgba(0, 0, 0, .3);

                &:last-child {
                    border-bottom: none;
                }

                br {
                    display: block;
                }
            }
			@media (max-width: 767px) {
			&.dispensable {
				display: none;
			}
			}
        }

		li > a.-active {
			color: var(--green);
		}

        &__item {
            font-size: 13px;
            font-weight: 500;
            text-align: center;
            text-decoration: none;
            display: block;
            padding: 20px 13px;
            position: relative;

            @media only screen and (min-width: 1520px) {
                padding: 13px;
            }

            span {
                display: block;
            }

            .icon {
                width: 54px;
                height: 54px;
                fill: var(--webColor);
                margin-bottom: 4px;
            }
        }

    }
}

.arrows {
	will-change: min-height;

	position: relative;
@media (min-width: 1200px) {
	display: none;
}
&.is-affixed {
	 opacity: 0.8;
 }

}


.arrows__inner {
	transform: translate(0, 0); /* For browsers don't support translate3d. */
	transform: translate3d(0, 0, 0);
	will-change: position, transform;
}

.right-arrow {
	float: right;
	fill: #ea1c24;
	position: relative;
}

.left-arrow {
	transform: rotate( -180deg );
	float: left;
	fill: #ea1c24;
	position: relative;
}

.sidebar-inner-sprinter{
	transform: translate(0, 0); /* For browsers don't support translate3d. */
	transform: translate3d(0, 0, 0);
	will-change: position, transform;
	position: static!important;
}

.right-sidebar-sprinter {
	will-change: min-height;

.right-menu {
	display: flex;

li {
	padding-left: 0;
	margin: 0;
	background: #F06C00;
	transition: background-color .3s;
	border-bottom: 1px solid rgba(0, 0, 0, .3);
	text-transform: uppercase;

&:hover {
	 background: var(--gray-dark);

a.right-menu__item {
	color: #ffffff;

.icon {
	fill: var(--green)
}
}
}

&::before {
	 content: none;
 }

&:last-child {
	 border-bottom: none;
 }

&.-active {
	 background: var(--gray-dark);
	 color: var(--green);
}

br {
	display: none;
}

	width: 16.6%;
	border-left: 3px solid white;
	border-bottom: none;

&:first-child {
	 border-left: none;
 }

@media (max-width: 767px) {
&.dispensable {
	display: none;
}
}
}

&__item {
	 font-size: 19px;
	 font-weight: 500;
	 text-align: center;
	 text-decoration: none;
	 display: block;
	 padding: 20px 13px;
	 position: relative;
 	color: #ffffff;

@media only screen and (min-width: 1520px) {
	padding: 13px;
}

span {
	display: block;
}
}

}
}

.right-sidebar-lerox {
	will-change: min-height;

.right-menu {
	display: flex;

li {
	padding-left: 0;
	margin: 0;
	background: #9A1913;
	transition: background-color .3s;
	border-bottom: 1px solid rgba(0, 0, 0, .3);
	text-transform: uppercase;

&:hover {
	 background: var(--gray-dark);

a.right-menu__item {
	color: #ffffff;

.icon {
	fill: var(--green)
}
}
}

&::before {
	 content: none;
 }

&:last-child {
	 border-bottom: none;
 }

&.-active {
	 background: var(--gray-dark);
	 color: var(--green);
 }

br {
	display: none;
}

width: 16.6%;
border-left: 3px solid white;
border-bottom: none;

&:first-child {
	 border-left: none;
 }

@media (max-width: 767px) {
&.dispensable {
	display: none;
}
}
}

&__item {
	 font-size: 19px;
	 font-weight: 500;
	 text-align: center;
	 text-decoration: none;
	 display: block;
	 padding: 20px 13px;
	 position: relative;
	 color: #ffffff;

@media only screen and (min-width: 1520px) {
	padding: 13px;
}

span {
	display: block;
}
}

}
}
