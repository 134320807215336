.main-content {
	padding: 30px 0;
    position:relative;
    min-height: 700px;
}

.main-content-sprinter {
	position:relative;
	min-height: 700px;
}

.main-content-lerox {
	position:relative;
	min-height: 700px;
}

.container.-rel {
    position: relative
}

.narrow-container {
    max-width: 945px;
}

.section {
    padding: 30px 0;
    
    &.-pt0 {
        padding-top: 0;
    }
}


.block {
	padding: 15px 0;
	margin: 15px 0;

	> *:last-child {
		margin-bottom: 0;
	}
}

.gray-box {
	padding: 20px;
	background: var(--gray-light)
}

.row.-m10 {
    margin-left: -10px;
    margin-right: -10px;

    [class*='col-'] {
        padding-left: 10px;
        padding-right: 10px;
    }
}

.header {
	margin-top: 20px;
}