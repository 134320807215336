h1 {
    font-size: 36px;
    line-height: 1.25em;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 25px;

    @media (--md-viewport) {
        font-size: 42px;
    }

    @media (--lg-viewport) {
        font-size: 50px;
    }
}

h2 {
    font-size: 25px;
    line-height: 1.25em;
    font-weight: 400;
    margin-bottom: 20px;
    color: var(--webColor);

    @media (--md-viewport) {
        font-size: 30px;
    }
}

h3 {
    font-size: 21px;
    line-height: 1.35em;
    font-weight: 400;
    margin-bottom: 15px;
    color: var(--red);

    @media (--md-viewport) {
        font-size: 24px;
    }
}

h4 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 15px;
}

._large-text {
    font-size: 18px;

    @media (--md-viewport) {
        font-size: 20px;
    }
}
