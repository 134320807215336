.news {
	padding-bottom:25px;
	margin-bottom:25px;
	border-bottom: 1px solid var(--gray-border);

	&:last-of-type {
		border-bottom: none;
	}

	>*:last-child {
		margin-bottom: 0;
	}

	&__title {
		font-size: 23px;
		color: var(--red-dark);
	}

	&__date {
		font-weight: 600;
		margin-bottom: 7px;
	}
}

