.button {
	font-size: 18px;
	font-weight: 600;
	font-family: var(--Neosans);
	color: #fff;
	background: var(--red);
	text-transform: uppercase;
	border-radius: 4px;
	padding: 15px 25px;
	margin-top: 15px;
	display: inline-block;
	border: none;
	text-decoration: none;
	cursor: pointer;
	outline: none;

	&:hover {
		color: #fff;
		opacity: .9;
	}

&.sprinter {
	 background: var(--sprinter-dark-orange);
	 border-radius: 0;
&.-icon {
	 padding-right: 23px;
 }
 }

	&.-icon {
		padding-right: 70px;
		position: relative;
        text-align: left;

		.icon {
			fill: #fff;
			width: 22px;
			height: 22px;
			position: absolute;
			right: 15px;
			top: 15px;

			&-trash {
				width: 25px;
				height: 25px;
				top: 13px;
			}
		}
		&.-compact {
			 padding-right: 23px;
		 }
	}

	&.-gray {
		background: var(--gray);
	}

	&.-small {
		font-size: 16px;
		padding: 10px 20px;
	}

	&.-ultrasmall {
		 font-size: 14px;
		 padding: 5px 10px;
	 }

	&.-outline {
		background: none;
		border: 2px solid #fff;
		padding-top: 11px;
		padding-bottom: 11px;

		.icon {
			top: 11px;
		}
	}
}

.buttons {
	.button + .button {
		margin-left: 10px;
	}
}

.new-password {
	float: right;
}
