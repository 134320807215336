@media print {
    .button,
    .top-menu,
    .right-sidebar,
    .page-header__menu,
    .page-header__right-col,
    .footer-menu,
    .payment-methods,
    .error-reporting-opener,
    .toggle-top-menu__button,
    .top-menu-toggle,
    .regional-prices,
    .slider-container,
    .pager,
    .tooltip,
    .no-print,
    .filter,
    form .form-row, form h3{
        display: none!important;
    }

    .top-content h1 {
        color: var(--webColor)
    }

    .page-header__contact {
        margin-top: -70px;
    }
    .main-content {
        min-height: 20px;
    }

    .page-footer {
        color: var(--webColor);

        h2, h3 {
            color: var(--webColor);
        }
    }

    table {
        width: 100%;
    }

    .orders-table {
        th {
            width: 20%;
        }
    }

    .cookies-agreement {
        display: none !important;
    }

    .page-footer__banners__banner {
        float: left;
        width: 46%;
        margin-right: 15px;
    }
}
