.page-header {
    &__inner {
        background: #f7f7f7;
        padding: 20px;
        border-radius: 0 0 8px 8px;
        position: relative;

        @media (--max-575-viewport) {
            margin-left: -16px;
            margin-right: -16px
        }

        @media (--lg-viewport) {
            max-width: 690px;
        }
    }

    &__right-col {
        padding-top: 15px;

        .button {
            min-width: 305px;
        }
    }

    &__logo {
        max-width: 126px;
        min-width: 80px;

        @media (--md-viewport) {
            max-width: none;
            min-width: 136px;
        }

        @media (--xxl-viewport) {
            min-width: 186px;
        }
    }

    &__contact {
        text-align: right;
        font-size: 25px;
        font-weight: 700;

        @media (--sm-viewport) {
            margin-top: -10px;
            
        }
        @media (--md-viewport) {
            margin-top: -20px;
            font-size: 30px;
        }

        @media (--xxl-viewport) {
            margin-top: -50px;
        }

       

        p {
            margin-bottom: 0;
        }

        .code {
            font-size: 20px;
            font-weight: 400;
        }

        .no-stop {
            font-size: 15px;
        }
    }

    .oreder-without-login, .oreder-with-login {
        text-align: center;

        .button {
            @media (--max-575-viewport) {
                padding-left: 16px;
                padding-right: 16px;
                font-size: 14px;
            }
        }
    }

    &__top-form {
        display: flex;
        justify-content: center;
        padding: 0 0 20px;
        margin: 15px 0 10px;

        @media (--lg-viewport) {
            margin: 0;
            justify-content: flex-end;
        }

        .to-registration, .login-link a {
            font-size: 18px;
            font-weight: 400;
            background: none;
            border: none;
            color: #fff;
            cursor: pointer;
            text-decoration: underline;

			&.sprinter {
				 color: #444;
			 }

            &:hover {
                text-decoration: none;
                color: #fff;
            }
        }

        .to-registration {
            padding-right: 15px;
            border-right: 1px solid rgba(255, 255, 255, .3);
        }

        .login-link a {
            padding-left: 15px;
        }

    }

    .registration {
        display: flex;
    }

    &__buttons {
        display: flex;
        justify-content: center;

        @media (--lg-viewport) {
            justify-content: flex-end;
        }

		@media (--xxl-viewport) {
			margin-left: 132px;
		}

		@media (--xl-viewport) {
			position: absolute;
			margin-left: 82px;
		}

        .button {
            min-width: 355px;

			@media (--max-575-viewport) {
				min-width: 320px;
				font-size: 15px;
				margin-left: 20px;
			}
        }
    }

    .login {
        display: flex;
        justify-content: center;

        @media (--lg-viewport) {
            justify-content: flex-end;
        }

        .login-form-container {
            position: relative;

            .login-title {
                @media (--sm-viewport) {
                    position:absolute;
                    left: -87px;
                }
            }

            .button {
                margin-top: 0;
            }
    
        } 
        .user {
            text-align: right;
			font-size: 18px;
        }

	.icon {
		fill: #fff;
		width: 22px;
		height: 22px;
		right: 15px;
		&.sprinter {
			 fill: #444;
		 }
	}


    }

    .link-new-order {
        text-align: center;

        @media (--lg-viewport) {
            text-align: left;
        }

        @media (--xl-viewport) {
            text-align: right;
            margin-top: -70px;
            margin-right: 20px;
        }

        @media (--xxl-viewport) {
            margin-right: 65px;
        }
    }
}

.main-menu {
    display: none;

    &.-opened {
        display: block;
    }

    @media (--md-viewport) {
        padding-left: 15px;
        display: block !important
    }

    ul {
        @media (--sm-viewport) {
            display: flex;
        }

        @media (--max-767-viewport) {
            justify-content: center;
            margin-top: 15px;
        }
    }

    li {
        font-size: 16px;
        padding: 0;
        margin-bottom: 0;

        &::before {
            content: none;
        }

        @media (--max-575-viewport) {
           margin-bottom: 10px;
           padding-bottom: 10px;
           border-bottom: 1px solid #ccc;
        }

        a {
            padding: 5px 11px;
            display: block;
            text-decoration: none;
            text-align: center;

            @media (--sm-viewport) {
                border-left: 1px solid rgba(0, 0, 0, .15);
                text=align: left;
            }

            @media (--xl-viewport) {
               padding-left: 18px;
               padding-right: 18px;
            }
        }

        &:first-child a {
            border-left: none;
        }

        &.-active a {
            color: var(--red);
        }
    }
}

.logo-picture {
	width: 110%;
	max-width: 220px;
}

.logo-picture-sprinter {
	width: 150%;
	max-width: 520px;
}

.payment-methods {
	margin: 25px 55px 0;

img {
	max-width: 260px;
}
}
