.paging {
    margin: 20px 0;
    text-align: center;

	&__item {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		width: 26px;
		height: 26px;
		padding: 7px;
		border: 1px solid transparent;
		text-decoration: none;

		&.-active {
			border-color: var(--gray-border);
			border-radius: 3px;
		}
	}
}