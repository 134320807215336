.slider-container {
    display: none;

    @media (--lg-viewport) {
        display: block;
    }
}

.slider {
    color: #fff;
    margin: 20px 0;

    &__item {
        max-width: 740px;
        height: 260px;
        display: inline-flex !important;
        align-items: center;

        &__img {
            flex-basis: 170px;
            min-width: 170px;
            padding-right: 20px;

            .icon {
                fill: #fff;
            }

            .icon-twenty-four-seven, .icon-man {
                width: 110px;
                height: 130px;

            }
        }

        &__content {
            flex-basis: 430px;
            position: relative;
        }

        .author {
            font-size: 18px;
        }

        .quote {
            font-size: 29px;
            line-height: 1.4em;
            font-weight: 700;
        }
    }
}

.tns-outer {
    position: relative
}

.tns-nav {
    position: absolute;
    bottom: 70px;
    left: 205px;
    z-index: 3;

    button {
        width: 14px;
        height: 14px;
        border: 2px solid #fff;
        border-radius: 50%;
        padding: 0;
        margin-right: 10px;
        outline: none;
        background: none;
        display: inline-block;

        &.tns-nav-active {
            background: #fff;
        }
    }
}

.box-with-shadow {
    position: absolute;
    right: 25px;
    bottom: 10px;

    &__content {
        display: inline-block;
        font-size: 17px;
        color: #333;
        background: var(--green);
        border-radius: 6px;
        padding: 8px 12px;
        transform: rotate(-10deg);
        position: relative;
        z-index: 1;
    }

    &:after {
        content: '';
        position: absolute;
        width: 100%;
        left: 10px;
        top: 9px;
        height: 100%;
        bottom: 0;
        border-radius: 6px;
        background: rgba(0, 0, 0, .3);
        transform: rotate(-6deg);
    }
}

.top-content .slider {
    height: 264px;
    overflow: hidden;
}
