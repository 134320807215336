.tooltip {
	display: inline-block;
	color: #fff;
	width: 22px;
	height: 22px;
	background: var(--orange);
	border-radius: 50%;
	border: none !important;
	position: relative;
    margin-left: 10px;

    &:hover {
        color: #fff;
    }

	&::after {
		content: '?';
		font-family: Arial, Verdana, sans-serif;
		font-size: 19px;
		font-weight: 700;
		position: absolute;
		left: 5px;
		top: 0;
	}
}

.tippy-tooltip {
	background: #fff;
	color: var(--webColor);
	border: 1px solid var(--gray-border);
	border-radius: 3px;
	box-shadow: 0 0 8px 1px rgba(0, 0, 0, .15);
}

.tippy-content {
	font-size: 15px;
	padding:10px;
	text-align:left;
}

.tippy-popper .tippy-tooltip .tippy-arrow {
	width: 14px;
	height: 14px;
	border: 1px solid var(--gray-border);
	border-top: none;
	border-right: none;
	background: #fff;
}

.tippy-popper[x-placement^='top'] .tippy-tooltip .tippy-arrow {
	transform: rotate(-45deg);
	bottom: -10px;
	margin-left: -3px;

}

.tippy-popper[x-placement^='bottom'] .tippy-tooltip .tippy-arrow {
	transform: rotate(135deg);
	top: -20px;
	margin-left: -3px;
}

.tippy-popper[x-placement^='left'] .tippy-tooltip .tippy-arrow {
	border-left-color: #fff;
}

.tippy-popper[x-placement^='right'] .tippy-tooltip.light-theme .tippy-arrow {
	border-right-color: #fff;
}

.contains-tooltip {
	display: inline-block;
	position:relative;
	padding-right: 35px;

	.tooltip {
		position: absolute;
		right:0;
		top:1px;
	}
}

.ui-tooltip {
	max-width: 400px !important;
}