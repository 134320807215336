.section-news {
    border-top: 1px solid var(--gray-border);
}

.hp-news {

    &__col {
        margin-bottom: 25px;
        padding-bottom: 25px;
        border-bottom: 1px solid var(--gray-border);

        &:last-child {
            padding-bottom: 0;
            margin-bottom: 0;
            border-bottom: none;
        }

        @media (--md-viewport) {
            padding-bottom: 0;
            font-size: 18px;
        }

        @media (--lg-viewport) {
            border-bottom: none;
        }
    }

    &__title {
        font-size: 20px;
        margin-bottom: 20px;
    }

    &__item {

        @media (--xl-viewport) {
            display: flex;
        }

        &__img {
            min-width: 170px;
            padding-right: 20px;
            margin-bottom: 20px;

            @media (--lg-viewport) {
                max-width: 170px;
            }

            img {
                border-radius: 6px;
                margin-bottom: 0;
            }
        }
    }
}