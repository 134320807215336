.orders-table {
    .price, .order-id, .address-from {
        display: block;
        margin-bottom: 7px;
    }

	.state {
		display: block;
	}

    .separator {
        padding: 0 11px;
        font-style: normal;
    }

    td.state-cell {
        background: var(--green-light) !important;

        &.-order-end {
            background: var(--green) !important;
        }
    }
}

.open-detail {
    .-active & {
        display: none;
    }
}

.close-detail {
    display: none;

    .-active & {
        display: inline-block;
    }
}

.order-info-row {
    display: flex;
    border-bottom: 1px solid var(--gray-border);
    margin-right: 30px;

    > div {
        padding: 10px 10px 10px 0;

        &:nth-child(1) {
            flex-basis: 100px;
        }
    }

    select, .custon-select {
        min-width: 200px;
    }
}

.note {
    display: flex;
    margin-top: 35px;

    > div:first-child {
        flex-basis: 100px;
        padding-right: 15px;
    }
}

.orders-sum {
    > div {
        display: flex;
        align-items: center;
        font-weight: 600;
        padding: 10px 25px 10px 0;
    }
}
