.page-footer {
	padding: 40px 0;
	background: #444 url(../images/footer-bg.jpg);
	&.sprinter {
		 background: #EE7D00 url(../images/sprinter/page-bg.png) center 5px no-repeat;
		 background-size: 600px;
	 }

	@media (--md-viewport) {
		padding: 60px 0;
	}

	&, a {
		color: #fff;
	}

	&__banners {
		padding: 40px 0;

		&__banner {
			img {
				border-radius: 5px;
				margin-bottom: 20px;

				@media (--md-viewport) {
					margin-bottom: 0;
				}
			}
		}
	}

	.copyright, .copyright a {
		color: #9f9f9f;
		margin-bottom: 0;
		text-align: center;

        @media (--lg-viewport) {
            text-align: left
        }
	}

	.error-reporting-opener {
		text-transform: uppercase;
	}

	&__top {

		.icon-map-eu {
			@media (--md-viewport) {
				position: relative;
				top: -40px;
			}
		}
	}

	&__bottom {
		position: relative;
	}
}

.regional-prices {
	margin-top: 20px;

	@media (--md-viewport) {
		margin-top: 35px;
	} @media (--xl-viewport) {
	margin-top: 0;
}

	[class*='col-'] {
		text-align: center;

		@media (--md-viewport) {
			text-align: left;
		}
	}

	&__item {
		position: relative;
		display: inline-block;
		margin-bottom: 30px;

		@media (--md-viewport) {
			margin-bottom: 0;
		}

		.region-name {
			font-size: 18px;
			position: absolute;
			top: 65px;
			left: 0;
			width: 100%;
			text-align: center;
		}

		.price-box {
			position: absolute;
			right: -13px;
			bottom: -5px;

			&__content {
				display: inline-block;
				padding: 8px 12px;
				background: #000;
				border-radius: 6px;
				transform: rotate(-10deg);
				position: relative;
				z-index: 1;
			}

			&:after {
				content: '';
				position: absolute;
				width: 100%;
				left: 10px;
				top: 9px;
				height: 100%;
				bottom: 0;
				border-radius: 6px;
				background: rgba(0, 0, 0, .3);
				transform: rotate(-6deg);
			}

			.price {
				font-size: 30px;
				color: var(--green);
			}
		}

		&.-eu .price-box {
			@media (--md-viewport) {
				bottom: 46px;
			}
		}

		&.-eu .region-name {
			@media (--max-767-viewport) {
				top: 104px
			}
		}

		.icon {
			fill: #fff;
		}
	}
}

.footer-menu {
	display: flex;
	justify-content: center;
	text-transform: uppercase;
	margin: 15px 0;
	flex-wrap: wrap;

	li {
		padding: 0;
		margin-bottom: 0;
		&:before {
			content: none;
		}

		a {
			padding: 0 12px;
			border-left: 1px solid rgba(255, 255, 255, .25)
		}

		&:first-child a {
			border-left: none;
		}

	}

	@media (--lg-viewport) {
		justify-content: flex-start;
		margin: 0;
	}
}
