.modal {
	padding: 25px 30px;

	.close-modal {
		background: var(--red)!important;
		border-radius: 50%;
		&.sprinter {
			 background: #EE7D00 !important;
		 }

		&::after, &::before {
			content: '';
			position: absolute;
			width:2px;
			height:14px;
			background:#fff;
			left: 50%;
			top:50%;
			margin-left: -1px;
			margin-top: -7px;
		}

		&::after {
			transform:rotate(-45deg)
		}
		&::before {
			transform:rotate(45deg)
		}
	}

	.form-row__input {
		flex-basis: 100%;

		input{
			width:100%
		}
	}
}

