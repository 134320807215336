ul {
	li {
		margin-bottom: 10px;
		position:relative;
		padding-left: 16px;

		&::before {
			content: '';
			position: absolute;
			width: 8px;
			height:8px;
			background: var(--webColor);
			border-radius: 50%;
			left: 0;
			top: 8px;
		}
	}
}

ul.fileHandlerIcons > li::before, ul.ui-autocomplete > li::before {
	content: none;
}
