.toggle-menu {
    font-size: 20px;
    text-decoration: none;
    color: #fff;
    padding: 15px 20px;
    background: var(--gray-dark);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;

    &__button {
        display: inline-block;
        position: relative;
        width: 35px;
        height: 28px;
        border-top: 4px solid #fff;
        border-bottom: 4px solid #fff;

        &::after {
            content: '';
            position: absolute;
            left:0;
            width: 100%;
            height: 4px;
            top: 50%;
            background: #fff;
            margin-top: -2px;
        }

        &.-active {
            transform: rotate(-90deg)
        }
    }

    @media (--md-viewport) {
        display: none;
    }
}

.top-menu-toggle {
    font-size: 19px;
    font-weight: 600;
    position: absolute;
    right: 25px;
    top: 45px;
    color: var(--red);
    display: flex;
    align-items: center;

    @media (--md-viewport) {
        display: none;
    }
}
.toggle-top-menu__button {
    display: inline-block;
    position: relative;
    margin-left:8px;
    width: 38px;
    height: 28px;
    border-top: 4px solid #000;
    border-bottom: 4px solid #000;

    &::after {
        content: '';
        position: absolute;
        left:0;
        width: 100%;
        height: 4px;
        top: 50%;
        background: #000;
        margin-top: -2px;
    }

    &.-active {
        transform: rotate(-90deg)
    }
}
