.top-menu-container {
	font-size: 19px;
    margin-bottom: 30px;
    padding-bottom: 15px;
    border-bottom: 1px solid var(--gray-border);
}

.top-menu {
    display: flex;
    flex-wrap: wrap;
    margin: 0;

    li {
        padding-left: 0;
        margin: 0 15px 15px 0;

        &::before {
            content: none;
        }
    }

    &__item {
        padding: 5px 10px 15px;
        font-weight: 500;
        text-decoration: none;
        position: relative;
        display: inline-block;

        .-active &:after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 6px;
            border-radius: 6px;
            background: var(--webColor)
        }
    }
}
