.bordered-list {
	li {
		padding: 25px 0;
		margin:0;

		&::before {
			content: none;
		}

		border-bottom: 1px solid var(--gray-border);

		p:last-child {
			margin-bottom: 0;
		}
	}
}