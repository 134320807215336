.drop-down-table {
	tr.-active {
		border-top:2px solid var(--gray-border);

		td {
			background: #fff!important;
			border-color: var(--gray-border);

		}
	}

	tbody {
		tr:nth-child(4n + 3) {
			td{
				background: var(--gray-light)
			}
		}
		.drop-down-row {
			display: none;

			&.-active {
				display: table-row;
				border-top: none;
				border-bottom:2px solid var(--gray-border);

				td {
					background: #fff;
					padding: 25px;
				}
			}

		}
	}
}

